<template>
  <div>
    <wallet />
  </div>
</template>

<script>
import wallet from "../components/Wallet.vue";
export default {
  components: {
    wallet
  }
};
</script>