<template>
  <div>
    <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick()">
      <el-tab-pane :label="label + '月'" name="1"></el-tab-pane>
      <el-tab-pane :label="labelB + '月'" name="2"></el-tab-pane>
      <el-tab-pane :label="labelC + '月'" name="3"></el-tab-pane>
      <table>
        <tr>
          <td v-for="(label,index) in listHead" :key="index">{{label}}</td>
        </tr>
        <tr v-for="(item,index) in accountHistory" :key="index+'c'">
          <td v-for="(value,key) in listHead" :key="key">
            <div v-if="key=='balance'">{{item.afterAmount*1+item.freezeAmount*1}}</div>
            <div v-else-if="key=='amount'">
              <div
                :class="[item.seqFlag=='转出'?'red':'',item.seqFlag=='转入'?'green':'']"
              >{{item.amount}}</div>
            </div>
            <div v-else>{{item[key]}}</div>
          </td>
        </tr>
      </table>
      <br />
      <div class="paginationwrap-g">
        <el-pagination
          layout="total,prev, pager, next"
          :page-size="count"
          :current-page="page"
          :page-count="9"
          :total="total"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </el-tabs>
  </div>
</template>

<script>
import { getAccountHistoryPc } from "@/api/api";
export default {
  data() {
    return {
      page: 1,
      count: 10,
      activeName: "1",
      label: "",
      labelB: "",
      labelC: "",
      total: 0,
      accountHistory: [],
      month: 1,
      listHead: {
        accountSn: "流水号",
        amount: "变动金额",
        afterAmount: "可用余额",
        freezeAmount: "冻结额",
        balance: "总余额",
        seqFlag: "变动类别",
        type: "交易类别",
        genTime: "时间"
      }
    };
  },
  mounted() {
    var rsp = new Date().getMonth(); //当月起前三个月
    if (rsp === 0) {
      this.labelB = rsp + 12;
      this.labelC = rsp + 11;
    } else if (rsp === 1) {
      this.labelB = rsp;
      this.labelC = rsp + 11;
    } else {
      this.labelB = rsp;
      this.labelC = rsp - 1;
    }
    this.label = rsp + 1;
    this.month = 1;
    this.page = 1;
    this.getWalletFn();
  },
  methods: {
    getWalletFn() {
      const that = this;
      getAccountHistoryPc({
        month: that.month,
        page: that.page,
        count: that.count
      }).then(res => {
        if (res.result) {
          that.accountHistory = res.data.account_history;
          that.total = res.data.account_total;
        }
      });
    },
    handleCurrentChange(s) {
      this.page = s;
      this.getWalletFn();
    },
    handleClick() {
      if (this.activeName == "1") {
        this.month = 1;
      } else if (this.activeName == "2") {
        this.month = 2;
      } else if (this.activeName == "3") {
        this.month = 3;
      }
      this.getWalletFn();
    }
  }
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}
table tr td {
  padding: 10px 0;
  width: 12%;
  text-align: center;
  border-bottom: 1px solid #eee;
}
.red {
  color: red;
}

.orange {
  color: rgb(163, 94, 8);
}

.green {
  color: mediumseagreen;
}
</style>