<template>
  <div>
    <div class="account-wrap">
      <el-row>
        <el-col :span="15" class="wallet-wrap">
          <div class="title">
            <h2>
              {{ subaccount.balance }}
              <span>元</span>
            </h2>
            <div>
              可提现：{{ subaccount.cashAble }}
              <span>元</span>
            </div>
            <div>
              提现中: {{ subaccount.freezeCashAmount }}
              <span>元</span>
            </div>
            <el-tooltip class="item" effect="dark" content="18:00前提现,24小时到账" placement="top">
              <el-button
                type="primary"
                @click="handleWithDraw"
                :disabled="biz.membType==1"
              >提现</el-button>
            </el-tooltip>
          </div>
        </el-col>

        <el-col :span="9" class="invoice-wrap">
          <div class="title">
            <div>待开票金额</div>
            <h2>
              {{ subaccount.invoiceAmount }}
              <span>元</span>
            </h2>
            <el-button type="text" @click="invoiceInfoShow=true">开票信息</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="明细账单" name="明细账单">
        <accounthistory :id="subaccount.subaccountId" />
      </el-tab-pane>
      <el-tab-pane label="开票记录" name="开票记录">
        <invoicehistory :id="subaccount.custId" />
      </el-tab-pane>
    </el-tabs>

    <!-- 提现弹出框 -->
    <el-dialog :visible.sync="withdrawShow" width="50%" :close-on-click-modal="false">
      <div class="center-g">
        <h3>提现申请</h3>
        <br />
        <el-divider>可提现金额： {{ subaccount.cashAble }} 元</el-divider>
        <div class="inputbox">
          <label>提现金额</label>
          <el-input placeholder="请输入提现金额" v-model="withdraw.amount">
            <template slot="append">
              <el-button
                @click="withdraw.amount = subaccount.cashAble"
                type="success"
                size="mini"
              >全部提现</el-button>
            </template>
          </el-input>
        </div>
        <el-button type="success" @click="submitWithDraw" size="mini">提交</el-button>
        <el-button @click="withdrawShow = false" size="mini">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="invoiceInfoShow" :close-on-click-modal="false">
      <div class="invoicebox">
        <div>新疆启荣能源科技有限公司</div>
        <div>91650100798151683D</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getWalletPc, withdrawBizPc } from "@/api/api";
import accounthistory from "../components/AccountHistory";
import invoicehistory from "../components/InvoiceHistory";
export default {
  components: {
    accounthistory,
    invoicehistory
  },
  data() {
    return {
      cashShow: false,
      showHelp: false,
      invoiceInfoShow: false,
      withdrawShow: false,
      activeName: "明细账单",
      withdraw: {
        withdrawSn: "",
        custId: "",
        custName: "",
        custType: "",
        state: "1",
        amount: "",
        genTime: "",
        authTime: "",
        operator: "",
        remark: ""
      },
      invoiceBalance: "", //subaccount seqflag ="转出"的支出总额 - refundLog type<2的总额-invoiceHistory state<2 的总额
      subaccount: {},
      total: 0,
      accountHistory: [],
      listHead: {
        accountSn: "流水号",
        amount: "变动金额",
        afterAmount: "余额",
        seqFlag: "变动类别",
        type: "交易类别",
        genTime: "时间"
      }
    };
  },
  computed: {
    biz() {
      return this.$store.getters.getBiz;
    }
  },
  mounted() {
    this.getWalletFn();
  },
  methods: {
    getWalletFn() {
      const that = this;
      getWalletPc({
        id: that.biz.id,
      }).then(res => {
        if (res.data) {
          that.subaccount = res.data.subaccount;
        }
      });
    },

    handleWithDraw() {
      //0.正常；1，禁止提现
      if (this.subaccount.state == 1) {
        this.$alert("当前账户禁止提现。如有问题，请联系客服！", "禁止提现", {
          confirmButtonText: "确定",
          type: "warning",
          callback: () => {
            return;
          }
        });
      } else if (this.biz.membType == 1) {
        this.$alert("只有管理员才可操作提现！", "禁止提现", {
          confirmButtonText: "确定",
          type: "warning",
          callback: () => {
            return;
          }
        });
      } else {
        this.withdrawShow = true;
      }
    },
    submitWithDraw() {
      const that = this;
      let amount = that.withdraw.amount;
      let text = "24小时内到账。";
      let genTime = new Date();
      if (genTime.getHours() > 18) {
        text = "48小时内到账。";
      }
      if (amount > that.subaccount.cashAble) {
        this.$alert("超出可提现金额", "修改提现额", {
          confirmButtonText: "确定",
          type: "warning",
          callback: () => {
            return;
          }
        });
      } else if (amount > 5000) {
        this.$alert("每日提现额不超出5000元", "超出单日限额", {
          confirmButtonText: "确定",
          type: "warning",
          callback: () => {
            return;
          }
        });
      } else {
        withdrawBizPc({
          amount: amount
        }).then(res => {
          if (res.result) {
            that.getWalletFn();
            that.$alert(text, "提现成功", {
              confirmButtonText: "确定",
              type: "success",
              callback: () => {
                that.withdrawShow = false;
                that.getWalletFn();
              }
            });
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.account-wrap {
  margin-bottom: 30px;
  border-radius: 3px;
}
.wallet-wrap {
  background: #efefef;
  border: 1px solid #efefef;
  padding: 20px 40px 10px 40px;
  border-radius: 5px 0 0 5px;
}
.invoice-wrap {
  padding: 20px 40px 10px 40px;
  border: 1px solid #eee;
  border-radius: 0 5px 5px 0;
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inputbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  margin: 40px auto;
}
.inputbox label {
  width: 100px;
  text-align: left;
}
.invoicebox {
  margin: 20px;
  line-height: 30px;
  text-align: center;
}
</style>