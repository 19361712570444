<template>
  <div class="box">
    <el-table :data="invoiceHistory" size="small">
      <el-table-column v-for="(value, key) in tableList" :key="key" :label="value" :prop="key"></el-table-column>
    </el-table>
    <div class="paginationwrap-g">
      <el-pagination
        layout="prev, pager, next"
        :page-size="count"
        :current-page="page"
        :page-count="9"
        :total="total"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getInvoiceList } from "@/api/api";
export default {
  data() {
    return {
      tableList: {
        invoiceSn: "流水号",
        custName: "单位名称",
        amountInvoice: "开票金额",
        state: "状态",
        remark: "说明",
        genTime: "提交时间"
      },
      invoiceHistory: [],
      page: 1,
      total: 0,
      count: 10
    };
  },
  mounted() {
    this.getInvoiceListFn();
  },
  methods: {
    handleCurrentChange(value) {
      this.page = value;
      this.getInvoiceListFn();
    },
    getInvoiceListFn() {
      const that = this;
      getInvoiceList({
        page: 1,
        count: that.count
      }).then(res => {
        if (res.result) {
          that.invoiceHistory = res.data.history;
          that.total = res.data.total;
        }
      });
    }
  }
};
</script>

<style scoped>
.box {
  border: 1px solid #eee;
}
</style>